<template>
    <y-modal ref="modal">
        <template slot="header">
            <div class="title">
                <i class="icon md-tag-plus" />
                {{ id ? $t('tagging.label.edit') : $t('tagging.label.create') }}
            </div>
        </template>

        <template slot="body">
            <y-loading
                :active="$wait.is('loading-form')"
                height="100px"
            >
                <form
                    id="create_label"
                    @submit.prevent="submit(save)"
                >
                    <y-form
                        v-if="form"
                        v-model="model"
                        :params="form"
                    />
                </form>
            </y-loading>
        </template>

        <template slot="footer">
            <div class="w-100 ta-l">
                <y-button
                    :key="id ? 'save-btn' : 'create-btn'"
                    color="green"
                    form="create_label"
                    loading-on="submitting"
                >
                    {{ id ? $t('button.save') : $t('button.create') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import { YModal, YForm } from '@deps';

    export default {
        name: 'YLabelModal',

        components: {
            YModal,
            YForm,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Folder data
             */
            folder: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                form : null,
                model: null,
                id   : null,
            };
        },

        methods: {

            /**
             * Fetch form
             *
             * @returns {*}
             */
            fetch() {
                this.$wait.start('loading-form');
                const type = this.id ? 'fetchLabel' : 'fetchNewLabel';
                const id = this.id || this.folder.id;
                return this.$services.Tagging[type]({ id }).then((response) => {
                    this.form = response.data.metadata.form;
                    this.model = response.data.results;
                }).catch((error) => {
                    this.close();
                    this.handleError(error);
                }).finally(() => this.$wait.end('loading-form'));
            },

            /**
             * Open Modal
             *
             * @param id
             */
            open(id) {
                this.id = id;
                this.$refs.modal.open();
                this.fetch();
            },

            /**
             * Close Modal
             */
            close() {
                this.$refs.modal.close();
                this.form = null;
                this.model = null;
                this.id = null;
            },

            /**
             * Save Label
             */
            save() {
                const params = {
                    id: this.id,
                    ...this.model,
                };
                this.$wait.start('submitting');
                return this.$services.Tagging.saveLabel(params).then(() => {
                    const type = this.id ? 'update' : 'create';
                    this.$toast.success(this.$t(`messages.tagging.${type}`));
                    this.close();
                    this.$emit('refresh');
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('submitting'));
            },
        },
    };
</script>
