<template>
    <div>
        <ul class="tags-loop">
            <li
                v-for="element in items"
                :key="`label-item-${element.id}`"
            >
                <section class="panel tag-item">
                    <header>
                        <div class="title">
                            <span>{{ element.title }}</span>
                            <i
                                v-if="!element.activeness"
                                class="fz-sm color-gray-light mr5"
                            >
                                {{ `(${$t('general.status.inactive')})` }}
                            </i>
                            <i
                                v-if="element.selected"
                                class="fz-sm star icon md-star mr5"
                            />
                        </div>
                        <div class="functions">
                            <a
                                href="#"
                                class="icon md-pencil-outline hover-color-blue"
                                @click.prevent="edit(element.id)"
                            />
                            <a
                                href="#"
                                class="icon md-delete-forever hover-color-red"
                                @click.prevent="remove(element.id)"
                            />
                        </div>
                    </header>
                </section>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'YFlatLoop',

        props: {
            /**
             * Tag Items
             */
            items: {
                type    : Array,
                required: true,
            },
        },

        methods: {

            /**
             * Remove item
             *
             * @param id
             */
            remove(id) {
                this.$emit('remove', id);
            },

            /**
             * Edit item
             *
             * @param id
             */
            edit(id) {
                this.$emit('edit', id);
            },
        },
    };
</script>
