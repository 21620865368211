<template>
    <y-loading
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <template v-if="!error && folder">
            <y-page-head
                :title="title"
                icon="md-tag-multiple"
            >
                <div slot="actions">
                    <y-button @click="goToList">
                        {{ $t('button.back_to_list') }}
                    </y-button>
                    <y-button
                        color="green"
                        @click="openLabelModal(null)"
                    >
                        {{ $t('tagging.label.create') }}
                    </y-button>
                </div>
            </y-page-head>

            <main>
                <y-row class="d-f justify-content-center">
                    <y-col md="8">
                        <div class="panel labels-loading">
                            <article>
                                <template v-if="labels && labels.length">
                                    <y-nested-loop
                                        v-if="isNested"
                                        :items="nestedLabels"
                                        :max="folder.nest_limit * 1"
                                        @edit="openLabelModal"
                                        @remove="openDeleteLabelModal"
                                        @start-loading="loading = true"
                                        @end-loading="loading = false"
                                        @order="saveOrder"
                                    />

                                    <y-flat-loop
                                        v-else
                                        :items="labels"
                                        @edit="openLabelModal"
                                        @remove="openDeleteLabelModal"
                                    />
                                </template>

                                <y-empty-state
                                    v-else
                                    class="mb0"
                                    height="300"
                                    :message="isNested ? $t('tagging.empty_state.category') : $t('tagging.empty_state.tag')"
                                />
                            </article>
                        </div>
                    </y-col>
                </y-row>
            </main>

            <!-- Label modal -->
            <y-label-modal
                ref="labelModal"
                :folder="folder"
                @refresh="reFetch"
            />

            <y-delete-label-modal
                ref="deleteLabelModal"
                @refresh="reFetch"
            />
        </template>

        <y-error v-else-if="error === 400" />
    </y-loading>
</template>

<script>

    import PageMixin from '@/mixins/Page';

    import { YEmptyState } from '@deps/EmptyState';
    import YError from '@deps/Error404';
    import YDeleteLabelModal from '@/modules/tagging/components/DeleteLabelModal';
    import YLabelModal from '@/modules/tagging/components/LabelModal';
    import YNestedLoop from '@/modules/tagging/components/NestedLoop';
    import YFlatLoop from '@/modules/tagging/components/FlatLoop';
    import { listToTree, treeToList } from '@nodes/helpers/misc';

    export default {
        name: 'TaggingManage',

        components: {
            YError,
            YEmptyState,
            YDeleteLabelModal,
            YLabelModal,
            YNestedLoop,
            YFlatLoop,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return this.breadcrumbs;
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('tagging.label.title'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {

                loading: false,

                folder: null,

                labels: null,

                nestedLabels: [],

                error: false,
            };
        },

        computed: {
            /**
             * Check if folder is nested
             */
            isNested() {
                return this.get(this.folder, 'features', []).indexOf('nested') > -1;
            },

            /**
             * Return page title
             */
            title() {
                return this.folder
                    ? this.$t('tagging.label.titleFull', [this.folder.title])
                    : this.$t('tagging.label.title');
            },

            /**
             * Return an array of breacrumb names
             */
            breadcrumbs() {
                return [
                    this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                    this.$bc(this.$t('breadcrumbs.settings')),
                    this.$bc('content-tagging-folders', this.$t('breadcrumbs.tagging.single')),
                    this.folder && this.folder.title,
                ].filter(Boolean);
            },
        },

        methods: {

            /**
             * Fetch data
             */
            fetch() {
                this.error = false;
                const params = {
                    slug        : this.$route.params.slug,
                    with_trashed: 1,
                };
                return this.$services.Tagging.labels(params).then((response) => {
                    this.folder = response.data.metadata.folder;
                    this.labels = response.data.results;
                    if (this.labels && this.labels.length) {
                        this.nestedLabels = listToTree(this.cloneDeep(this.labels));
                    }
                    this.setBreadcrumb();
                }).catch((error) => {
                    this.error = error.response.status;
                    this.handleError(error);
                });
            },

            /**
             * Open label modal
             *
             * @param id
             */
            openLabelModal(id) {
                this.$refs.labelModal.open(id);
            },

            /**
             * Open label modal
             *
             * @param id
             */
            openDeleteLabelModal(id) {
                this.$refs.deleteLabelModal.open(id);
            },

            /**
             * Delete a label
             */
            saveOrder() {
                const params = {
                    labels: treeToList(this.nestedLabels),
                    id    : this.folder.id,
                };
                this.$emit('start-loading');
                this.$services.Tagging.saveOrders(params).then(() => {
                    this.$toast.success(this.$t('messages.tagging.save_order'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$emit('end-loading');
                });
            },

            /**
             * Go to categories list
             */
            goToList() {
                this.$router.push({ name: 'content-tagging-folders' });
            },

            /**
             * Set page's breadcrumb in store
             */
            setBreadcrumb() {
                this.$store.dispatch('breadcrumbs/set', this.breadcrumbs);
            },
        },
    };
</script>
