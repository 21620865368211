<template>
    <y-modal ref="modal">
        <template slot="body">
            <p>{{ $t('tagging.label.delete_confirm') }}</p>
        </template>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    loading-on="label-deleting"
                    @click.prevent.native="destroy"
                >
                    {{ $t('button.sure') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal } from '@deps';

    export default {

        name: 'DeleteLabelModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id: null,
            };
        },

        methods: {

            /**
             * Open delete modal
             *
             * @param id
             */
            open(id) {
                this.id = id;
                this.$refs.modal.open();
            },

            /**
             * Close delete modal
             */
            close() {
                this.id = null;
                this.$refs.modal.close();
            },

            /**
             * Delete a label
             */
            destroy() {
                this.$wait.start('label-deleting');
                this.$services.destroy('tagging-label', this.id).then(() => {
                    this.$toast.success(this.$t('messages.delete.success', { type: this.$t('tagging.type') }));
                    this.close();
                    this.$emit('refresh');
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('label-deleting');
                });
            },

        },

    };
</script>
