<template>
    <draggable
        v-bind="dragOptions"
        ref="Draggable"
        class="label-loop"
        :data-level="level"
        handle=".label-handle"
        tag="ul"
        :list="items"
        :move="handleMove"
        :group="{ name: 'g1' }"
        @end="saveOrder"
    >
        <li
            v-for="element in items"
            :key="`label-item-${element.id}`"
            :data-level="level"
        >
            <section class="panel label-item">
                <header>
                    <div class="title">
                        <i class="icon md-menu label-handle" />
                        {{ element.title }}
                        <i
                            v-if="!element.activeness"
                            class="fz-sm color-gray-light mr5"
                        >
                            {{ `(${$t('general.status.inactive')})` }}
                        </i>
                        <i
                            v-if="element.selected"
                            class="fz-sm star icon md-star mr5"
                        />
                    </div>
                    <div class="functions">
                        <a
                            href="#"
                            class="icon md-pencil-outline ml5 hover-color-blue"
                            @click.prevent="edit(element.id)"
                        />
                        <a
                            href="#"
                            class="icon md-delete-forever hover-color-red"
                            @click.prevent="remove(element.id)"
                        />
                    </div>
                </header>
            </section>

            <y-nested-loop
                :items="element.children"
                :level="level + 1"
                :max="max"
                @edit="edit"
                @remove="remove"
                @start-loading="$emit('start-loading')"
                @end-loading="$emit('end-loading')"
                @order="saveOrder"
            />
        </li>
    </draggable>
</template>

<script>

    import Draggable from 'vuedraggable';

    export default {

        name: 'YNestedLoop',

        components: {
            Draggable,
        },

        props: {
            /**
             * Label Items
             */
            items: {
                required: true,
                type    : Array,
            },

            /**
             * Level of nested
             */
            max: {
                type   : Number,
                default: 5,
            },

            /**
             * Level of nested
             */
            level: {
                type   : Number,
                default: 1,
            },

        },

        computed: {
            /**
             * @inheritDoc
             */
            dragOptions() {
                return {
                    animation : 200,
                    ghostClass: 'ghost',
                };
            },

        },

        methods: {

            /**
             * Remove item
             *
             * @param id
             */
            remove(id) {
                this.$emit('remove', id);
            },

            /**
             * Edit item
             *
             * @param id
             */
            edit(id) {
                this.$emit('edit', id);
            },

            /**
             * Save order
             */
            saveOrder() {
                this.$emit('order');
            },

            /**
             * Handle move event to check max level
             *
             * @param evt
             */
            handleMove(evt) {
                const fromLevel = evt.from.dataset.level * 1;
                const toLevel = evt.to.dataset.level * 1;

                if (toLevel <= fromLevel) {
                    return true;
                }

                return evt.to.dataset.level <= this.max && !evt.draggedContext.element.children.length;
            },
        },

    };
</script>
